<div
  class="uploader-main-container"
  [class.two-one]="aspectRatio == 2"
  [style.height.px]="height"
  [style.width.px]="width"
  [class.fluid-width]="fluidWidth"
  [class.display-none]="
    (hasImage || hasVideo) && useImagePreviewComponent && !editMode
  "
  [class.invalid-uploader]="invalid"
>
  <div [style.height.px]="height" class="full-width">
    <ngx-file-drop
      headertext=""
      (onFileDrop)="dropped($event)"
      [style.height.px]="height"
      class="full-width"
      [class.no-logo-view]="logoViewType === 'REGULAR'"
      [class.cover-image-view]="logoViewType === 'COVER_IMAGE'"
      [class.image-logo-view]="
        logoViewType === 'IMAGE_LOGO' || logoViewType === 'TEXT_LOGO'
      "
    >
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector"
      >
        <input
          #fileInput
          type="file"
          [multiple]="multiple"
          (change)="fileChangeEvent($event)"
          class="hidden-input"
          accept="{{ fileTypes }}"
        />
        <ng-container *ngIf="logoViewType === 'COVER_IMAGE'">
          <img class="cover-image" [src]="coverImage" />
        </ng-container>
        <ng-container *ngIf="logoViewType === 'REGULAR'">
          <div *ngIf="!hasImage" class="asset-upload d-flex-center">
            <i class="fal fa-plus"></i>
          </div>
        </ng-container>
        <div class="uploader-inner-cont" *ngIf="logoViewType === 'IMAGE_LOGO'">
          <ng-container *ngIf="!hasImage && !hasVideo">
            <img class="upload-photo" src="/assets/upload/upload-photo.png" />
            <div class="upload-label">
              @if (uploaderType === "VIDEO") {
                {{ "APP.UPLOADER.DRAG_VIDEO" | translate }}
              } @else {
                {{ "APP.UPLOADER.DRAG_IMAGE_OR_VIDEO" | translate }}
              }
            </div>
            <div class="hr-text hr-text-internal">
              <span>{{ "APP.OR" | translate }}</span>
            </div>
          </ng-container>

          @if (!multiple) {
            <div
              *ngIf="hasImage || hasVideo"
              [style.height.px]="height"
              class="full-width"
            >
              @if (hasVideo) {
                @if (!fileBase64 && selectedFile && selectedFile.path) {
                  <video
                    controls="controls"
                    [src]="selectedFile.path"
                    type="video/mp4"
                    width="249px"
                    height="140px"
                  ></video>
                } @else {
                  <video
                    controls="controls"
                    [src]="fileBase64"
                    type="video/mp4"
                    width="249px"
                    height="140px"
                  ></video>
                }
              } @else {
                @if (showCropper) {
                  <app-image-cropper
                    [imageBase64]="fileBase64 ? fileBase64 : ''"
                    [aspectRatio]="aspectRatio"
                    [resizeToWidth]="resizeToWidth"
                    (imageCropped)="imageCropped($event)"
                    (cancel)="cancelCropper()"
                  >
                  </app-image-cropper>
                } @else {
                  <img
                    *ngIf="fileBase64"
                    class="image-preview"
                    [src]="fileBase64"
                  />
                  <img
                    *ngIf="!fileBase64 && selectedFile && selectedFile.path"
                    class="image-preview"
                    [src]="selectedFile.path"
                  />
                }
              }
            </div>
          }
          @if (!showCropper) {
            <div class="text-link" (click)="fileInput.click()">
              {{ "APP.BROWSE_FILE" | translate }}
            </div>
          }
        </div>

        <ng-container *ngIf="logoViewType === 'TEXT_LOGO' && !hasImage">
          <img class="upload-photo" src="/assets/upload-logo.png" />
          <div class="upload-label text-logo-upload-label">Logo hochladen</div>
        </ng-container>
      </ng-template>
    </ngx-file-drop>

    <div class="actions-cont" *ngIf="hasImage || hasVideo">
      @if (hasImage) {
        <div class="crop-icon" (click)="onCropImage($event)">
          <i class="fal fa-crop"></i>{{ "APP.CROP" | translate }}
        </div>
      }
      <div class="delete-icon" (click)="onDeleteFile($event)">
        <i class="fal fa-trash"></i>{{ "APP.DELETE" | translate }}
      </div>
    </div>
  </div>
</div>

@if (
  !multiple && (hasImage || hasVideo) && useImagePreviewComponent && !editMode
) {
  <app-image-preview
    [selectedFile]="selectedFile"
    (deleteFile)="onDeleteFile()"
    (editFile)="onEditImage()"
  ></app-image-preview>
}

@if (multiple && selectedFiles.length > 0) {
  <div class="files-cont" [class.data-editable-cont]="dataEditable">
    <div
      cdkDropList
      cdkDropListOrientation="horizontal"
      class="files-list"
      (cdkDropListDropped)="dragDropped($event)"
    >
      @for (
        mulSelectedFile of selectedFiles;
        track mulSelectedFile.base64 || mulSelectedFile.path || mulSelectedFile
      ) {
        <app-image-preview
          cdkDrag
          [cdkDragData]="mulSelectedFile"
          [dataEditable]="dataEditable"
          [selectedFile]="mulSelectedFile"
          (deleteFile)="onDeleteFileMultiple(mulSelectedFile)"
          (editFile)="onEditImageMultiple(mulSelectedFile)"
          (updateFile)="onUpdateFileMultiple($event)"
          ><div cdkDragHandle class="drag-handle">
            <i class="far fa-grip-vertical"></i></div
        ></app-image-preview>
      }
    </div>
  </div>
}
